

export const serif = `
font-family: Georgia;
`

export const textFont = `
font-family: 'Annie Use Your Telescope', cursive;
`

export const headerFont = `
font-family: 'Fredericka the Great', cursive;
font-wight:400;
`