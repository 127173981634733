import styled from 'styled-components'

export const GridView = styled.div`
display: grid;
grid-template-columns: auto auto;
grid-template-rows: auto auto;
justify-content:space-between;
align-items: center;
grid-gap:5%;
margin: 0 auto;
max-width:1000px;
`