
import styled from 'styled-components'

export const Header = styled.header`
position:relative;
overflow:hidden;
  min-height: calc(100vh);
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
  display: flex;
  justify-content: center;
  align-items: center;
`





